import { defineStore } from "pinia";

export const useMandateStore = defineStore("mandate", {
  state: () => ({
    mandate: null
  }),

  actions: {
    async setMandate(mandate) {
      this.mandate = mandate;
    }
  }
});
