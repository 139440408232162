import { useAuthStore } from "@/store/modules/AuthStore";
import { axiosBaseService } from "./axiosBase.service";
import { useLocaleStore } from "@/store/modules/Locale.store";
/**
 * Resets the password
 */

const refreshToken = async () => {
  try {
    const localeStore = useLocaleStore();
    const authStore = useAuthStore();
    const url = `/${localeStore.locale}/usersapi/token/refresh/`;
    const response = await axiosBaseService.post(url, {
      refresh: authStore.refreshToken
    });
    if (response.status !== 200) {
      return response.statusText;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export { refreshToken };
