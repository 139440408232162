import { defineStore } from "pinia";
import { refreshToken } from "../../services/refreshToken.service";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    accessToken: sessionStorage.getItem("access_token") || null,
    refreshToken: sessionStorage.getItem("refresh_token") || null,
    loginError: false,
    loginCompleted: false,
    isLoggedIn: JSON.parse(sessionStorage.getItem("isLoggedIn")) || false
  }),

  actions: {
    loginSuccess() {
      this.loginCompleted = true;
      this.loginError = false;
    },
    loginFailure(data) {
      this.loginError = data;
    },
    updateSessionStorage(data) {
      sessionStorage.setItem("access_token", data.access);
      if (data.refresh) {
        sessionStorage.setItem("refresh_token", data.refresh);
      }
    },
    clearStorage() {
      sessionStorage.clear();
    },
    emptyTokenState() {
      this.accessToken = null;
      this.refreshToken = null;
    },
    updateTokenState(data) {
      this.accessToken = data.access;
      if (data.refresh) {
        this.refreshToken = data.refresh;
      }
    },
    updateRegisteredState() {
      this.registered = !this.registered;
    },
    updateIsLoggedIn(data) {
      sessionStorage.setItem("isLoggedIn", data);
      this.isLoggedIn = data;
    },

    setSessionStorage(data) {
      this.updateSessionStorage(data);
    },
    setTokenState(data) {
      this.updateTokenState(data);
    },
    setLoginSuccess() {
      this.loginSuccess();
    },
    setLoginFailure(data) {
      this.loginFailure(data);
    },
    destroyTokenStateAndStorage() {
      this.emptyTokenState();
      this.clearStorage();
      this.updateIsLoggedIn(false);
    },
    clearLoginStatus() {
      this.loginCompleted = false;
      this.loginError = false;
    },
    async submitRefreshToken() {
      await refreshToken().then((response) => {
        this.updateSessionStorage(response.data);
        this.updateTokenState(response.data);
      });
    },
    setIsLoggedIn(data) {
      this.updateIsLoggedIn(data);
    }
  }
});
