<template>
  <div v-if="!isLoading">
    <header class="header s-pdgv-05">
      <div class="container-fluid flex justify-between align-center w-100">
        <a :href="mandate.homepage" v-if="mandate">
          <img
            class="header__logo"
            :src="mandate.logo"
            alt="Logo"
            title="Home"
            @error="usePlaceholder"
          />
        </a>
        <button class="header__menu-toggle" @click.prevent.stop="toggleMenu">
          <svg-icon name="bars" class="icon icon--big" />
        </button>
        <div class="header__menu" :class="{ 'is-open': isMenuOpen }">
          <button
            class="menu__close align-center"
            @click.prevent.stop="toggleMenu"
          >
            <svg-icon name="times" class="icon icon--big" />
          </button>
          <ul class="list-style--none list">
            <li
              v-for="(link, index) in mandate?.mandateLinks?.slice(0, 5)"
              :key="link.name"
              :class="
                [0, 3, 4].includes(index) ? 'header__separator--mb' : 'links-b'
              "
            >
              <a :href="link.link" class="dp-block link s-pdgv-02">
                <span>
                  {{ link.name }}
                </span>
              </a>
            </li>
            <li @click.prevent.stop="toggleMenu">
              <router-link
                class="dp-block link s-pdgv-02"
                :to="{
                  name: 'MoviesListView',
                  params: {
                    lang: locale
                  }
                }"
              >
                {{ $t("Movie.toMoviesOverview") }}
              </router-link>
            </li>
            <li @click="toggleMenu">
              <router-link
                class="dp-block link s-pdgv-02"
                :to="{
                  name: 'MemberProfileListView',
                  params: {
                    lang: locale
                  }
                }"
                >{{ $t("NavBar.members") }}
              </router-link>
            </li>
            <li @click="toggleMenu">
              <router-link
                class="dp-block link s-pdgv-03 header__separator--mb"
                :to="{
                  name: 'Companies',
                  params: {
                    lang: locale
                  }
                }"
                >{{ $t("NavBar.companies") }}
              </router-link>
            </li>
            <li
              v-if="isLoggedIn && hasMovieCreatePermission"
              @click="toggleMenu"
            >
              <router-link
                class="dp-block link s-pdgv-02"
                :to="{
                  name: 'MovieCreateView',
                  params: {
                    lang: locale
                  }
                }"
              >
                {{ $t("Movie.submitMovie") }}
              </router-link>
            </li>
            <li
              v-if="isLoggedIn && hasMovieCreatePermission"
              @click="toggleMenu"
            >
              <router-link
                class="dp-block link s-pdgv-02"
                :to="{
                  name: 'UserMovieListView',
                  params: {
                    lang: locale
                  }
                }"
              >
                {{ $t("Movie.userMovieList") }}
              </router-link>
            </li>
            <li
              v-if="isLoggedIn && authUser && authUser.isMember"
              @click="toggleMenu"
            >
              <router-link
                class="dp-block link s-pdgv-02 header__separator--mb"
                :to="userProfileLink"
              >
                {{ userProfileTitle }}
              </router-link>
            </li>
            <li v-if="!isLoggedIn" @click="toggleMenu">
              <router-link
                class="dp-block link s-pdgv-02"
                :to="{
                  name: 'Login',
                  params: {
                    lang: locale
                  }
                }"
                >{{ $t("Login.login") }}</router-link
              >
            </li>
            <li v-if="isLoggedIn" @click="toggleMenu">
              <router-link
                class="dp-block link s-pdgv-02"
                :to="{
                  name: 'Logout',
                  params: {
                    lang: locale
                  }
                }"
                >{{ $t("Login.logout") }}</router-link
              >
            </li>
            <li
              v-for="link in mandate?.mandateLinks?.slice(5, 7)"
              :key="link.name"
              class="header__separator--mb"
            >
              <a :href="link.link" class="dp-block link s-pdgv-02">
                <span>
                  {{ link.name }}
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="header__menu-overlay"></div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/store/modules/AuthStore';
import { useUserStore } from '@/store/modules/UserStore';
import { useMandateStore } from '@/store/modules/Mandate.store';
import { useMemberProfileStore } from '@/store/modules/MemberProfileStore';
import { useLocaleStore } from '@/store/modules/Locale.store';

export default {
  name: "NavBarComponent",
  data() {
    return {
      isLoading: true,
      isMenuOpen: false
    };
  },
  computed: {
    ...mapState(useUserStore, ["hasMovieCreatePermission"]),
    ...mapState(useAuthStore, ["isLoggedIn"]),
    ...mapState(useUserStore, ["authUser"]),
    ...mapState(useMandateStore, ["mandate"]),
    ...mapState(useLocaleStore, ["locale"]),
    ...mapState(useUserStore, ["isCompany"]),
    ...mapState(useMemberProfileStore, ["memberProfile"]),
    isMember() {
      return !!(this.authUser && this.authUser.isMember);
    },
    userProfileLink() {
      const memberLink = {
        name: this.authUser.memberProfile
          ? "MemberProfileDetailView"
          : "MemberProfileCreateView",
        params: {
          MpId: this.authUser.memberProfile,
          lang: this.locale
        }
      };
      const companyLink = {
        name: "Company",
        params: { Cid: this.authUser.companies[0], lang: this.locale }
      };

      if (this.isCompany) {
        return companyLink;
      } else if (this.memberProfile) {
        return memberLink;
      } else {
        return { name: "MemberProfileCreateView" };
      }
    },
    userProfileTitle() {
      const memberText = this.$t("MemberProfile.profile");
      const companyText = this.$t("Company.profile");
      return this.isCompany ? companyText : memberText;
    }
  },
  methods: {
    ...mapActions(useMemberProfileStore, ["setMemberProfile"]),
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    onClickHandler(event) {
      const menu = document.querySelector(".header__menu");
      const menuRect = menu.getBoundingClientRect();
      if (this.isClickInMenu(event, menuRect) && this.isMenuOpen) {
        this.isMenuOpen = false;
      }
    },
    isClickInMenu(event, menuRect) {
      // We have a little edge case here. If we open the Menu it slides in from the right end.
      // Because of that animation we have to check if the menu is still sliding or if it is opened
      if (window.innerWidth === menuRect.left) {
        return false;
      }
      return event.clientX <= menuRect.left;
    },
    usePlaceholder(e) {
      e.target.src = "/img/logo.png";
    }
  },
  async mounted() {
    this.isLoading = true;
    if (this.isLoggedIn && this.authUser) {
      await this.setMemberProfile(this.authUser.memberProfile);
    }
    addEventListener("click", this.onClickHandler);
    this.isLoading = false;
  },
  beforeDestroy() {
    removeEventListener("click", this.closeMenuOnClick);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/base.scss";
.header {
  position: fixed;
  width: 100%;
  height: 100px;
  background-color: $color-white;
  left: 0;
  top: 0;
  z-index: 100;
}
.header__menu-toggle {
  display: none !important;
}
.header__menu {
  transform: translateX(100%);
  position: fixed;
  overflow: scroll;
  width: 100%;
  max-width: 480px;
  height: 100%;
  background-color: $color-white;
  transition: 0.2s transform ease-in-out;
  top: 0;
  right: 0;
  padding: 2rem;
  border-left: 2px solid $color-black;
  z-index: 2;
  &.is-open {
    transform: translateX(0);
  }
  .menu__close {
    display: flex !important;
    border: none;
    background: transparent;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}
.header__menu-toggle {
  display: block !important;
  border: none;
  background: transparent;
}
.header__menu-overlay {
  background-color: rgba(0, 0, 0, 0);
  transition: 0.2s background-color ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
}
.header__menu.is-open + .header__menu-overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.header__logo {
  width: 200px;
}
.header__separator--mb {
  margin-bottom: 1.5rem;
}
.link {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1.5em;
  letter-spacing: 0;
  line-height: 26px;
}
.link:hover {
  font-style: italic;
  text-decoration: none;
}
.list {
  margin-top: 60px;
}
</style>
