import { defineStore } from "pinia";
import { useAuthStore } from "./AuthStore";
import { getUserList } from "@/services/User.service";

export const useUserStore = defineStore("user", {
  state: () => ({
    authUser: JSON.parse(localStorage.getItem("authUser")) || null,
    userList: []
  }),

  getters: {
    isCompany: (state) => {
      return state.authUser?.companies?.length > 0;
    },
    hasMovieCreatePermission: (state) => {
      return !!(state.authUser && state.authUser.isMovieCreator);
    },
    hasMovieUpdatePermission: (state) => {
      return (movieCreator) => {
        const authStore = useAuthStore();
        if (!state.authUser || !authStore.isLoggedIn) {
          return false;
        }
        return state.authUser.id === movieCreator || state.authUser.isSuperuser;
      };
    }
  },

  actions: {
    setLocalStorage(data) {
      localStorage.setItem("authUser", JSON.stringify(data));
    },
    setUserState(data) {
      this.authUser = data;
    },
    destroyUserStateAndStorage() {
      localStorage.clear();
      this.authUser = null;
    },
    async setUserList() {
      const userList = await getUserList();
      this.userList = userList;
    }
  }
});
