import { createRouter, createWebHistory } from "vue-router";

const Root = () => import("./Root.vue")
const MoviesListView = () => import("@/views/MoviesListView.vue")
const PasswordResetConfirm = () => import("@/views/PasswordResetConfirm.vue")
const PasswordResetEmail = () => import("@/views/PasswordResetEmail.vue")
const NotFoundView = () => import("@/views/NotFoundView.vue")
const UserMovieListView = () => import("@/views/UserMovieListView.vue")
const CompanyEditView = () => import("@/views/Companies/CompanyEditView.vue")
const MovieCreateView = () => import("@/views/MovieCreateView.vue")
const MovieDetailView = () => import("@/views/MovieDetailView.vue")
const Login = () => import("@/views/Login.vue")
const Logout = () => import("@/views/Logout.vue")
const MemberProfileCreateView = () => import("@/views/MemberProfiles/MemberProfileCreateView.vue")
const MemberProfileDetailView = () => import("@/views/MemberProfiles/MemberProfileDetailView.vue")
const MemberProfileListView = () => import("@/views/MemberProfiles/MemberProfileListView.vue")
const MemberProfileEditView = () => import("@/views/MemberProfiles/MemberProfileEditView.vue")
const MovieEditView = () => import("@/views/MovieEditView.vue")
const Companies = () => import('@/views/Companies/Companies.vue')
const Company = () => import("@/views/Companies/Company.vue")

import { getMandate } from "@/services/mandate.service";
import { languages } from "@/i18n";
import { useLocaleStore } from "@/store/modules/Locale.store";
import { useMandateStore } from "@/store/modules/Mandate.store";
import { useAuthStore } from "@/store/modules/AuthStore";
import { useUserStore } from "@/store/modules/UserStore";

async function configureMandate() {
  const mandate = await getMandate(window.location.hostname);
  document.title = mandate[0].name;
  if (mandate[0].fontFamily) {
    document.body.style.fontFamily = mandate[0].fontFamily;
  }
  return mandate[0];
}

const routes = [
  {
    //redirects the request of root URL (/) to /:lang
    // by passing the current locale next(store.state.locale).
    path: "/",
    name: "root",
    beforeEnter(to, from, next) {
      const localeStore = useLocaleStore();
      next(localeStore.locale);
    }
  },
  {
    path: "/:lang",
    component: Root,
    async beforeEnter(to, from, next) {
      const mandate = await configureMandate();
      const mandateStore = useMandateStore();
      const localeStore = useLocaleStore();
      mandateStore.setMandate(mandate);

      let lang = to.params.lang;
      if (languages.includes(lang)) {
        if (localeStore.locale !== lang) {
          localeStore.changeLocale(lang);
        }
        return next();
      }
      return next({ path: localeStore.locale });
    },
    children: [
      {
        path: "",
        name: "MoviesListView",
        component: MoviesListView,
        props: true
      },
      {
        path: "movie/:Mid",
        name: "MovieDetail",
        component: MovieDetailView
      },
      {
        path: "create",
        name: "MovieCreateView",
        component: MovieCreateView,
        beforeEnter(to, from, next) {
          const userStore = useUserStore();
          const localeStore = useLocaleStore();

          if (userStore.authUser) {
            next();
          } else {
            next({
              name: "Login",
              params: { lang: localeStore.locale }
            });
          }
        }
      },
      {
        path: "/:lang/password_reset/:token/",
        name: "PasswordResetConfirm",
        component: PasswordResetConfirm
      },
      {
        path: "/:lang/forgot-password/",
        name: "PasswordResetEmail",
        component: PasswordResetEmail
      },
      {
        path: "login",
        name: "Login",
        component: Login,
        beforeEnter(to, from, next) {
          const userStore = useUserStore();
          const localeStore = useLocaleStore();
          const authStore = useAuthStore();
          if (
            userStore.authUser &&
            authStore.isLoggedIn &&
            userStore.authUser.isMember
          ) {
            next({
              name: "root",
              params: { lang: localeStore.locale }
            });
          } else {
            next();
          }
        }
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout
      },
      {
        path: "members/create",
        name: "MemberProfileCreateView",
        component: MemberProfileCreateView,
        beforeEnter(to, from, next) {
          const userStore = useUserStore();
          const localeStore = useLocaleStore();
          const authStore = useAuthStore();
          if (
            userStore.authUser &&
            authStore.isLoggedIn &&
            userStore.authUser.isMember
          ) {
            next();
          } else {
            next({
              name: "MoviesListView",
              params: { lang: localeStore.locale }
            });
          }
        }
      },
      {
        path: "members/:MpId",
        name: "MemberProfileDetailView",
        component: MemberProfileDetailView
      },
      {
        path: "members/",
        name: "MemberProfileListView",
        component: MemberProfileListView,
      },
      {
        path: "members/:MpId/edit",
        name: "MemberProfileEditView",
        component: MemberProfileEditView,
        beforeEnter(to, from, next) {
          const userStore = useUserStore();
          const localeStore = useLocaleStore();
          const authStore = useAuthStore();
          if (
            userStore.authUser &&
            authStore.isLoggedIn &&
            userStore.authUser.isMember &&
            userStore.authUser.memberProfile ===
              parseInt(to.params.MpId)
          ) {
            next();
          } else {
            next({
              name: "MoviesListView",
              params: { lang: localeStore.locale }
            });
          }
        }
      },
      {
        path: "movie/:Mid/edit",
        name: "MovieEditView",
        component: MovieEditView
      },
      {
        path: "user/movies",
        name: "UserMovieListView",
        component: UserMovieListView
      },
      {
        path: "company/",
        name: "Companies",
        component: Companies
      },
      {
        path: "company/:Cid",
        name: "Company",
        component: Company
      },
      {
        path: "company/:Cid/edit",
        name: "CompanyEditView",
        component: CompanyEditView,
        props: true
      },
      {
        path: "*",
        name: "NotFoundView",
        component: NotFoundView
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

// This makes sure create movie is only for logged in users
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const localeStore = useLocaleStore();

  if (to.fullPath === `/${localeStore.locale}/create`) {
    if (authStore.accessToken === null) {
      return next(`/${localeStore.locale}/login`);
    }
  }
  return next();
});
export default router;
