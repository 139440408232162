import { defineStore } from "pinia";
/* import i18n, { selectedLocale } from "../plugins/i18n"; */
import i18n from "@/i18n";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    locale: "de"
  }),

  actions: {
    changeLocale(newLocale) {
      i18n.global.locale.value = newLocale;
      this.locale = newLocale;
    }
  }
});
