/**
 * @file Filmdatenbank
 * Ein Plattform für Mitglieder & Firmen um ihre Filme zu veröffentlichen.
 * @author: Liip.ch
 * @version 1.0
 */

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/";
import { createPinia } from "pinia";

//Setry
import { initializeSentry } from './services/sentry.service.js';

// Styles
import "@/assets/styles/base.scss";
import "vue3-toastify/dist/index.css";

// Plugins
import i18n from "./i18n";
import Vue3Toastify from "vue3-toastify";

// Global components
import Multiselect from "vue-multiselect";
import SvgIcon from "@/components/SvgIcon.vue";

let app = null;
const setupApp = (app) => {
  app.config.productionTip = false;
  app.use(router);
  app.use(i18n);
  app.use(createPinia());

  app.use(Vue3Toastify);

  // Global components
  app.component("multiselect", Multiselect);
  app.component("SvgIcon", SvgIcon);

  initializeSentry(app, import.meta.env, router);

  app.mount("#app");
};

app = createApp(App);
setupApp(app);
