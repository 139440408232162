<template>
  <div id="app" class="wrap container-fluid s-pdgb-08">
    <div class="row">
      <div class="col-xs-12">
        <NavBarComponent />
        <div class="l-pdgt-07">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBarComponent from "@/components/NavBarComponent.vue";
import { mapStores, mapState } from 'pinia'
import { useLocaleStore } from "./store/modules/Locale.store";

export default {
  name: "app",
  components: {
    NavBarComponent
  },
  computed: {
    ...mapStores(useLocaleStore),
    ...mapState(useLocaleStore,["locale"])
  },
  watch: {
    locale() {
      this.$router.replace({ params: { lang: this.locale } }).catch(() => {});
    }
  },
  created() {
    this.localeStore.changeLocale(this.locale)
  },
};
</script>
