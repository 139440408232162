import { defineStore } from "pinia";
import { getMemberProfileDetail } from "@/services/memberProfile.service";

export const useMemberProfileStore = defineStore("memeberProfile", {
  state: () => ({
    memberProfile: null,
    memberProfileList: []
  }),

  actions: {
    async setMemberProfileDetail(id) {
      const memberProfile = await getMemberProfileDetail(id);
      this.memberProfile = memberProfile;
    },
    setMemberProfile(memberProfile) {
      this.memberProfile = memberProfile;
    },
    destroyMemberProfile() {
      this.memberProfile = null;
    }
  }
});
