import { createI18n } from "vue-i18n";
import { dateTimeFormats } from "@/locales/formats/dataTimeFormats";

function loadLocaleMessages() {
  const locales = import.meta.glob("./locales/*.json", { eager: true });
  const messages = {};
  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales[key];
    }
  });
  return messages;
}

function checkDefaultLanguage() {
  let matched = null;
  let languages = Object.getOwnPropertyNames(loadLocaleMessages());
  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split("-")[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split("-")[0];
      if (lang.split("-")[0] === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}

export const languages = Object.getOwnPropertyNames(loadLocaleMessages());
export const selectedLocale =
  checkDefaultLanguage() || import.meta.env.VITE_I18N_LOCALE || "de";

export default createI18n({
  dateTimeFormats,
  legacy: false,
  locale: selectedLocale,
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "de",
  globalInjection: true,
  messages: loadLocaleMessages()
});
